import React from "react";
import { View, StyleSheet } from "react-native";
import DayBox from "./DayBox";

const DayGrid = () => {
  const numbers = Array.from({ length: 28 }, (_, index) => index + 1);

  return (
    <View style={styles.container}>
      {numbers.map((number) => (
        <DayBox key={number} number={number} />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: 950,
  },
});

export default DayGrid;
