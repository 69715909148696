import React from "react";
import { View, Text, Button } from "react-native";
import StyleSheet from "react-native-media-query";
import DayBox from "../components/DayBox";

// const months = [
//   { name: "January", abbreviation: "Jan" },
//   { name: "February", abbreviation: "Feb" },
//   { name: "March", abbreviation: "Mar" },
//   { name: "April", abbreviation: "Apr" },
//   { name: "May", abbreviation: "May" },
//   { name: "June", abbreviation: "Jun" },
//   { name: "Sol", abbreviation: "Sol" },
//   { name: "July", abbreviation: "Jul" },
//   { name: "August", abbreviation: "Aug" },
//   { name: "September", abbreviation: "Sep" },
//   { name: "October", abbreviation: "Oct" },
//   { name: "November", abbreviation: "Nov" },
//   { name: "December", abbreviation: "Dec" },
// ];

const dayNumbers = Array.from({ length: 28 }, (_, index) => index + 1);

const HomeScreen = ({ navigation }) => {
  const today = dayOfYearTo13MonthCalendar(getDayOfYear());
  let displayDate =
    today.month.toUpperCase() + " " + ordinalSuffix(today.day).toUpperCase();

  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      <Text style={styles.subText} dataSet={{ media: ids.subText }}>
        today is
      </Text>
      <Text style={styles.title} dataSet={{ media: ids.title }}>
        {displayDate}
      </Text>
      <Text style={styles.subText} dataSet={{ media: ids.subText }}>
        on a 13 month calendar
      </Text>
      <View style={styles.grid} dataSet={{ media: ids.grid }}>
        {dayNumbers.map((number) => (
          <DayBox number={number} todayNumber={today.day} />
        ))}
      </View>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  grid: {
    marginTop: 50,
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)", // 7 items per row
    gridGap: 25,
    justifyContent: "center",
    alignItems: "center",
    width: 795,

    "@media (max-width: 800px)": {
      gridGap: 15,
      width: 450,
    },
    "@media (max-width: 480px)": {
      gridGap: 10,
      width: 300,
    },
  },
  title: {
    fontFamily: "Prata",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 82,
    lineHeight: 111,
    textAlign: "center",
    color: "#000000",

    "@media (max-width: 800px)": {
      fontSize: 42,
      lineHeight: 60,
    },
    "@media (max-width: 480px)": {
      fontSize: 32,
      lineHeight: 40,
    },
  },
  subText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 18,
    lineHeight: 21,
    textAlign: "center",
    color: "#5B5B5B",

    "@media (max-width: 480px)": {
      fontSize: 14,
      lineHeight: 18,
    },
  },
});

// Example usage:
// const dayOfYear = 195;
// const result = dayOfYearTo13MonthCalendar(dayOfYear);
// console.log(result); // { month: 'Sol', day: 7 }
function dayOfYearTo13MonthCalendar(dayOfYear) {
  if (dayOfYear < 1 || dayOfYear > 365) {
    throw new Error("Invalid day of the year. Must be between 1 and 365.");
  }

  // Handle New Year's Day
  if (dayOfYear == 1) {
    //
    return { month: "New Year's Day", day: null };
  } else {
    dayOfYear--; // adjust to make Jan 2nd be the day 1 of the 13 month calendar
  }

  const daysInMonth = 28;
  const solMonth = 6; // 0-based index of the Sol month (between June and July)

  let month = Math.floor((dayOfYear - 1) / daysInMonth);
  let day = ((dayOfYear - 1) % daysInMonth) + 1;

  if (month > solMonth) {
    month++; // Adjust for the Sol month
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "Sol",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return { month: monthNames[month], day: day };
}

// Example usage:
// const dayOfYear = getDayOfYear();
// console.log(dayOfYear);
function getDayOfYear() {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const diffInMilliseconds = now - startOfYear;
  const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

  return Math.floor(diffInMilliseconds / oneDayInMilliseconds) + 1;
}

// Example usage:
// console.log(ordinalSuffix(1)); // "1st"
function ordinalSuffix(number) {
  const mod10 = number % 10;
  const mod100 = number % 100;

  if (mod10 === 1 && mod100 !== 11) {
    return number + "st";
  } else if (mod10 === 2 && mod100 !== 12) {
    return number + "nd";
  } else if (mod10 === 3 && mod100 !== 13) {
    return number + "rd";
  } else {
    return number + "th";
  }
}

export default HomeScreen;
