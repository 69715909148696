import React from "react";
import { View, StyleSheet } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";

const GradientBorder = ({ children }) => {
  return (
    <LinearGradient
      colors={["#F800EE", "#1300EB"]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
      style={styles.gradientContainer}
    >
      <View style={styles.innerBox}>{children}</View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  gradientContainer: {
    padding: 2,
  },
  innerBox: {
    flex: 1,
    backgroundColor: "white",
  },
});

export default GradientBorder;
