import React from "react";
import { View, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import GradientBorder from "./GradientBorderView";

const DayBox = ({ number, todayNumber }) => {
  const isToday = number == todayNumber;
  const textStyle = getTextStyle(number, todayNumber);

  const gradientBox = (
    <GradientBorder>
      <View style={styles.box} dataSet={{ media: ids.box }}>
        <Text
          style={[textStyle, styles.dayText]}
          dataSet={{ media: ids.dayText }}
        >
          {number}
        </Text>
      </View>
    </GradientBorder>
  );

  return isToday ? (
    gradientBox
  ) : (
    <View style={styles.box} dataSet={{ media: ids.box }}>
      <Text
        style={[textStyle, styles.dayText]}
        dataSet={{ media: ids.dayText }}
      >
        {number}
      </Text>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  box: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(241, 241, 241, 0.5)",
    paddingVertical: 24,
    paddingHorizontal: 35,

    "@media (max-width: 800px)": {
      paddingVertical: 16,
      paddingHorizontal: 24,
    },
    "@media (max-width: 480px)": {
      paddingVertical: 12,
      paddingHorizontal: 18,
    },
  },
  dayText: {
    fontFamily: "Prata",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 22,
    lineHeight: 30,

    textAlign: "center",

    "@media (max-width: 800px)": {
      fontSize: 18,
      lineHeight: 20,
    },
    "@media (max-width: 480px)": {
      fontSize: 14,
      lineHeight: 16,
    },
  },
  darkText: {
    color: "#000000",
  },
  lightText: {
    color: "rgba(0, 0, 0, 0.3)",
  },
});

const getTextStyle = (number, todayNumber) => {
  if (number == todayNumber || number > todayNumber) {
    return styles.darkText;
  } else {
    return styles.lightText;
  }
};

export default DayBox;
